import React, {useEffect, useState} from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/layout'
import view from "../components/blogPage/blog.module.scss";


const EventCategory = (props) => {
  const {pageContext} = props;
  const posts = pageContext.searchedPosts;
  const blogManagement = pageContext.blogmanagement;
  const allBlogPagesBottomHTML = blogManagement.allBlogPagesBottomHTML;
  const categoriesBlogResultsHTML = blogManagement.categoriesBlogResultsHTML;
  const blogResultsTopHTML = blogManagement.blogResultsTopHTML;
  const pageSeo = {seo:{
    MetaDesc: `Explore the latest events and updates from ${pageContext.dealerName} and stay informed by reading our latest news about upcoming and past events.`,
    MetaTitle: `Events Posts | Upcoming and Previous | ${pageContext.dealerName}`,
    tags: []}
  }

  const [formattedPosts, setFormattedPosts] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

    // function to format publication date to display Month, Day, Year
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        if (isNaN(date.getTime())) {
            // Invalid date format, return input as is
            return inputDate;
        }

        const options = { 
            month: 'long', 
            day: 'numeric', 
            year: 'numeric' 
        };

        return date.toLocaleDateString(undefined, options);
    };

    // function to shorten blog title to specified number of characters and add elipsis at the end
    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    };

    // function to convert various date formats to mm/dd/yyyy
    function convertDate(dateString) {
        const date = new Date(dateString);
        if (!isNaN(date) && /^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
            // Date is already in the preferred format
            return dateString;
        } else if (!isNaN(date)) {
            // convert the number to a Date object and format it
            const formattedDate = new Date(date);
            return (formattedDate.getMonth() + 1).toString().padStart(2, '0') + '/' + formattedDate.getDate().toString().padStart(2, '0') + '/' + formattedDate.getFullYear();
        } else {
            // try to parse other formats
            const parsedDate = Date.parse(dateString);
            if (!isNaN(parsedDate)) {
            const parsed = new Date(parsedDate);
            // convert to preferred format
            return (parsed.getMonth() + 1).toString().padStart(2, '0') + '/' + parsed.getDate().toString().padStart(2, '0') + '/' + parsed.getFullYear();
            } else {
            // if unable to parse, return null
            return null;
            }
        }
    };

    const isFutureDate = (dateString) => {
        const today = new Date();
        const date = new Date(dateString);
        return date > today;
    };


    useEffect(() => {
        const newPosts = posts.map(post => { 
            // parse each content string to access the blogposts object
            const content = JSON.parse(post.content);
            post.blogposts = content.blogposts;
        
            // convert the publicationDate of each post and set it as the value of convertedPublicationDate
            // if publicationDate fails to parse and returns as null, convertedPublicationDate will not be created
            const publicationDate = convertDate(post.blogposts.publicationDate);
            if (publicationDate !== null) {
                post.blogposts.convertedPublicationDate = publicationDate;
            }
            post.blogposts.isFutureDate = isFutureDate(post.blogposts.convertedPublicationDate);
            return post;
            });

            //sort posts by date, considering invalid dates
            const sortByDate = (a, b) => {
                const dateA = new Date(a.blogposts.convertedPublicationDate);
                const dateB = new Date(b.blogposts.convertedPublicationDate);

                // check if dates are valid and move them to the end if needed
                if (!dateA || !dateB) {
                    if (!dateA && !dateB) {
                    return 0; // if both dates are invalid, leave them where they are
                    } else if (!dateA) {
                    return 1; // if dateA is invalid, move it to the end
                    } else {
                    return -1; // if dateB is invalid, move it to the end
                    }
                }

                return dateA - dateB;
            };

            // separate future and past events
            const future = newPosts.filter(post => post.blogposts.isFutureDate);
            const past = newPosts.filter(post => !post.blogposts.isFutureDate);
            
            // sort future posts by date ascending
            future.sort((a, b) => sortByDate(a, b));
            // sort past posts by date descending
            past.sort((a, b) => sortByDate(b, a));

            setFutureEvents(future);
            setPastEvents(past);
            setFormattedPosts(newPosts);
        }, [posts]);

  return(
    <Layout seo={pageSeo}>
      <div dangerouslySetInnerHTML={{ __html: blogResultsTopHTML }} />
      <div style={{ width: "95%", margin: '0 auto', maxWidth: 2000, padding: "0 15px", minHeight: "70vh"}}>

        {futureEvents.length > 0 ? (
            <div>
            <h1 className={`${view['category-page-header']}`}><span  className={`${view['tag-title']}`}>UPCOMING EVENTS</span></h1>
            <ul style={{display: 'flex', flexWrap:'wrap', justifyContent: 'center', alignItems: 'stretch'}}>
              {futureEvents.map(post => {
                const title = JSON.parse(post.pageTitle);
                const slug = JSON.parse(post.slug); 
                const content = JSON.parse(post.content);
                const id = JSON.parse(post._id);
                const parsedContent = content.blogposts;
                let formattedDate = formatDate(parsedContent.publicationDate);
                let truncatedTitle = truncateString(title, 100);

                return(
                  <li className={view["category-card__container"]} key={id} >
                    <Link className={view["category-card"]} href={slug}>
                      <div className={view["category-card__image"]}>
                        <div style={{backgroundImage:`url(${parsedContent.thumbnailImage ? parsedContent.thumbnailImage:null})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width:'100%', height:'100%', backgroundPosition: 'center'}}></div>
                      </div>
                      <div className={view["category-card__flexible"]} style={{paddingTop:10}}>
                        {parsedContent.publicationDate ? <h3 className={view["category-card-date"]}>{formattedDate}</h3> : null }
                        {title ? <p className={view["category-card-title"]}>{truncatedTitle}</p> : null }
                        <div className={view["category-card-read-more"]}>
                          <h3>READ MORE</h3>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
            <div>
                <h1 className={`${view['category-page-header']}`}><span  className={`${view['tag-title']}`}>UPCOMING EVENTS</span></h1>
                <p className={`${view['events-page-text']}`}>Contact us for more information about upcoming events.</p>
                <a className={`${view['events-page-cta-a']}`} href="?title=Contact%20Us&comments=I%20would%20like%20more%20information%20about%20upcoming%20events.#contact-form">
                    <div className={`${view['events-page-cta']}`}>Contact Us</div>
                </a>
            </div>
        )}

        <h1 className={`${view['category-page-header']}`}><span  className={`${view['tag-title']}`}>PAST EVENTS</span></h1>
        <ul style={{display: 'flex', flexWrap:'wrap', justifyContent: 'center', alignItems: 'stretch'}}>
          {pastEvents.map(post => {
            const title = JSON.parse(post.pageTitle);
            const slug = JSON.parse(post.slug); 
            const content = JSON.parse(post.content);
            const id = JSON.parse(post._id);
            const parsedContent = content.blogposts;
            let formattedDate = formatDate(parsedContent.publicationDate);
            let truncatedTitle = truncateString(title, 100);

            return(
              <li className={view["category-card__container"]} key={id} >
                <Link className={view["category-card"]} href={slug}>
                  <div className={view["category-card__image"]}>
                    <div style={{backgroundImage:`url(${parsedContent.thumbnailImage ? parsedContent.thumbnailImage:null})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width:'100%', height:'100%', backgroundPosition: 'center'}}></div>
                  </div>
                  <div className={view["category-card__flexible"]} style={{paddingTop:10}}>
                    {parsedContent.publicationDate ? <h3 className={view["category-card-date"]}>{formattedDate}</h3> : null }
                    {title ? <p className={view["category-card-title"]}>{truncatedTitle}</p> : null }
                    <div className={view["category-card-read-more"]}>
                      <h3>READ MORE</h3>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      {/*BLOG RESULTS DATA */}
      <div dangerouslySetInnerHTML={{ __html: categoriesBlogResultsHTML }} />

      {/* ALL BLOG DATA */}
      <div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
    </Layout>
  )
}

   














//         <ul style={{display: 'flex', flexWrap:'wrap', justifyContent: 'center', alignItems: 'stretch'}}>
//           {formattedPosts.map(post => {
            
//             const title = JSON.parse(post.pageTitle);
//             const slug = JSON.parse(post.slug); 
//             const content = JSON.parse(post.content);
//             const id = JSON.parse(post._id);
//             const parsedContent = content.blogposts;
//             let formattedDate = formatDate(parsedContent.publicationDate);
//             let truncatedTitle = truncateString(title, 100)

//             return(
//               <li className={view["category-card__container"]} key={id} >
//                 <Link className={view["category-card"]} href={slug}>
//                   <div className={view["category-card__image"]}>
//                     <div style={{backgroundImage:`url(${parsedContent.thumbnailImage ? parsedContent.thumbnailImage:null})`, backgroundSize:'cover', backgroundRepeat:'no-repeat', width:'100%', height:'100%', backgroundPosition: 'center'}}></div>
//                   </div>
//                   <div className={view["category-card__flexible"]} style={{paddingTop:10}}>
//                     {parsedContent.publicationDate ? <h3 className={view["category-card-date"]}>{formattedDate}</h3> : null }
//                     {title ? <p className={view["category-card-title"]}>{truncatedTitle}</p> : null }
//                     <div className={view["category-card-read-more"]}>
//                       <h3>READ MORE</h3>
//                     </div>
//                   </div>
//                 </Link>
//               </li>
//             )
//           })}
//         </ul>
//       </div>
//       {/*BLOG RESULTS DATA */}
//       <div dangerouslySetInnerHTML={{ __html: categoriesBlogResultsHTML }} />

//       {/* ALL BLOG DATA */}
//       <div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
//     </Layout>
//   )
// }

export default EventCategory;